import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Hero from "../components/hero/hero"
import "../style/utility/special-headings.scss"
import Timeline from "../components/timline/timeline"
import TextSection from "../components/text-section/text-section"
import kit from "../docs/USLPA_Media_Kit.pdf"
import reps from "../docs/USLPA_Team_Reps_2023.pdf"
import cba from "../docs/2021_USL_Collective_Bargaining_Agreement.pdf"
import L1cba from "../docs/2022_L1_Collective_Bargaining_Agreement.pdf"


const ResourcesPage = props => {
  const data = props.data
  const events = data.events.edges

  return (
    <Layout>
      <SEO title="USL Championship CBA Resources" />
      <Hero title="USL Championship CBA Resources" />
      <div className="white-content">
        <TextSection>
          <div className="contact-button-wrapper">
            <a href={cba} target="_blank" rel="noreferrer" className="button">
              USL Championship CBA
            </a>
            <a href={L1cba} target="_blank" rel="noreferrer" className="button">
              USL League 1 CBA
            </a>
            <a href={kit} target="_blank" rel="noreferrer" className="button">
              Media Kit
            </a>
            <a href={reps} target="_blank" rel="noreferrer" className="button">
              Who's Your Rep?
            </a>
          </div>
        </TextSection>
      </div>
      <div className="about--wrapper container">
        <h2 className="special-heading--left spaced-out">CBA Timeline</h2>
        <Timeline events={events} />
      </div>
    </Layout>
  )
}

export default ResourcesPage

export const pageQuery = graphql`
  query {
    events: allCbaTimelineJson {
      edges {
        node {
          date
          event
        }
      }
    }
  }
`
